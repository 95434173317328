import type { DashboardVariable } from 'dashboard-engine/types/Dashboard';
const KEY_PREFIX = 'SQUP_VARIABLE_SCOPE-';

interface ScopeData {
    selectedAll: boolean;
    selectedObjects: { id: string; name: string }[];
}

const makeKey = (scopeId: string, multipleSelect: boolean) => `${KEY_PREFIX}-${scopeId}-${multipleSelect}`;

export const invalidateScopeCache = (scopeId: string | undefined) => {
    if (scopeId === undefined) {
        return;
    }
    // Invalidate any versions of this storage variable
    localStorage.removeItem(makeKey(scopeId, false));
    localStorage.removeItem(makeKey(scopeId, true));
};

export const updateScopeIfPresent = (variable: DashboardVariable) => {
    const storageString = localStorage.getItem(
        makeKey(variable.scopeId, variable.allowMultipleSelection || variable.selectedAll)
    );
    if (storageString === null || storageString === '') {
        // No data present, no change required
        return variable;
    }

    let newVariable = { ...variable };

    try {
        const newScope: ScopeData = JSON.parse(storageString);
        newVariable = {
            ...variable,
            ...newScope
        };
    } catch (e) {
        // Malformed storage data. No change possible
        invalidateScopeCache(variable.id);
    }

    return newVariable;
};

export const saveScopeToStorage = (scopeId: string, allowMultipleSelection: boolean, scopeData: ScopeData) => {
    localStorage.setItem(
        makeKey(scopeId, allowMultipleSelection || scopeData.selectedAll),
        JSON.stringify({
            selectedAll: scopeData.selectedAll,
            selectedObjects: scopeData.selectedObjects
        })
    );
};
