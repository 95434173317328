import Text from '@/components/Text';
import { isFeatureEnabled } from '@squaredup/tenants';
import { isDefined } from '@squaredup/utilities';
import { Presence } from 'components/Presence';
import CustomACLEditor, { type PermissionOption } from 'components/accessControl/CustomACLEditor';
import ToggleField from 'components/accessControl/ToggleField';
import { buttonVariants } from 'components/button';
import { useEveryoneGroup } from 'components/hooks/useEveryoneGroup';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';
import type { AccessControlEntryModel } from 'dynamo-wrapper';
import { useWorkspaceDetailedPermissions } from 'pages/settings/workspaces/useWorkspaceDetailedPermissions';
import { useTier } from 'queries/hooks/useTier';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { AccessControlQueryKeys } from 'services/AccessControlService';
import Auth from 'services/Auth';
import { Workspace } from 'services/WorkspaceService';

type WorkspaceModalAccessControlTabProps =
    | {
          modalType: 'edit-workspace';
          workspace: Workspace;
      }
    | {
          modalType: 'create-workspace';
      };

export const WorkspaceModalAccessControlTab = (props: WorkspaceModalAccessControlTabProps) => {
    return (
        <>
            <AccessControlToggle {...props} />

            <WorkspaceACLTable {...props} />

            <DashboardsSharingToggle {...props} />
        </>
    );
};

const AccessControlToggle = (props: WorkspaceModalAccessControlTabProps) => {
    const formCtx = useFormContext();

    const workspace = props.modalType === 'edit-workspace' ? props.workspace : undefined;
    const workspaceDetailedPermissions = useWorkspaceDetailedPermissions(workspace);
    const { data: tier } = useTier();
    const isACLFeatureEnabled = tier && isFeatureEnabled(tier, 'accessControl');

    return (
        <Presence isPresent={Boolean(workspaceDetailedPermissions.canConfigureAccessControl)}>
            <Controller
                name='accessControlToggle'
                render={(toggleProps) => (
                    <ToggleField
                        onToggle={toggleProps.field.onChange}
                        toggleDefaultValue={toggleProps.field.value}
                        label='Restrict access to this workspace'
                        help='Configure access control to this workspace by user, group and access level.'
                        toggleTestId='accessControlToggle'
                        toggleDisabled={!isACLFeatureEnabled}
                        upgradePill={<FeatureUnavailablePill featureKey='accessControl' className='ml-3'/>}
                    />
                )}
            />

            <Presence isPresent={!formCtx.getValues('accessControlToggle')}>
                <Text.SmallBody className='mt-1 ml-5 text-textSecondary'>
                    Everyone in your organization currently has access to this workspace
                </Text.SmallBody>
            </Presence>
        </Presence>
    );
};

const WorkspaceACLTable = (props: WorkspaceModalAccessControlTabProps) => {
    const formCtx = useFormContext();
    const aclController = useController({ name: 'acl' });
    const workspaceACLUtils = useWorkspaceACLUtils();

    const workspace = props.modalType === 'edit-workspace' ? props.workspace : undefined;
    const workspaceDetailedPermissions = useWorkspaceDetailedPermissions(workspace);
    const aclToggleController = useController({ name: 'accessControlToggle' });

    const addingNewACLHandler = (updatedACL: AccessControlEntryModel[]) => {
        formCtx.clearErrors('noFullControl');

        if (!workspaceACLUtils.isACLValid(updatedACL)) {
            formCtx.setError('noFullControl', {
                type: 'customError',
                message: 'At least one user or group must have full control.'
            });
        }

        formCtx.setValue('acl', updatedACL);
    };

    return (
        <Presence isPresent={Boolean(workspaceDetailedPermissions.canConfigureAccessControl)}>
            <Presence isPresent={aclToggleController.field.value}>
                <CustomACLEditor
                    acl={aclController.field.value}
                    entityId={props.modalType === 'edit-workspace' ? props.workspace.id : undefined}
                    permissionOptions={permissionOptions}
                    defaultPermissionsForNewACE={['RO']}
                    onChange={addingNewACLHandler}
                />
            </Presence>

            <Presence isPresent={Boolean(formCtx.formState.errors['noFullControl']?.message)}>
                <p className='mt-2 ml-2 text-statusErrorPrimary'>
                    {formCtx.formState.errors['noFullControl']?.message}
                </p>
            </Presence>
        </Presence>
    );
};

const DashboardsSharingToggle = (props: WorkspaceModalAccessControlTabProps) => {
    const workspace = props.modalType === 'edit-workspace' ? props.workspace : undefined;
    const workspaceDetailedPermissions = useWorkspaceDetailedPermissions(workspace);
    const allowedToShare = workspaceDetailedPermissions.canConfigureOpenAccess;

    if (!allowedToShare) {
        return (
            <div className='mt-5'>
                <ToggleField
                    onToggle={() => null}
                    toggleDefaultValue={false}
                    label='Allow view-only link sharing for all dashboards in this workspace'
                    help='Allow dashboards in this workspace to be shared with anyone.'
                    toggleTestId='openAccessEnabled'
                    toggleDisabled={!allowedToShare}
                    upgradePill={
                        <FeatureUnavailablePill 
                            featureKey='openAccess'
                            title='Unlimited dashboard viewers'
                            summary='Configure and manage unlimited sharing of view-only dashboards.'
                            className='ml-3'
                        />
                    }
                >
                    <Text.SmallBody className='mt-1 ml-6 text-textSecondary'>
                        This feature is not enabled for this organization.{' '}
                        <Link
                            to='/settings/openaccess/'
                            className={buttonVariants({ variant: 'link' })}
                            target='_blank'
                        >
                            Configure sharing settings
                        </Link>
                    </Text.SmallBody>
                </ToggleField>
            </div>
        );
    }

    return (
        <div className='mt-5'>
            <Controller
                name='openAccessEnabled'
                render={(toggleProps) => (
                    <ToggleField
                        onToggle={toggleProps.field.onChange}
                        toggleDefaultValue={toggleProps.field.value}
                        label='Allow view-only link sharing for all dashboards in this workspace'
                        help='Allow dashboards in this workspace to be shared with anyone.'
                        toggleTestId='openAccessEnabled'
                        toggleDisabled={!allowedToShare}
                    />
                )}
            />
        </div>
    );
};

export const permissionOptions: PermissionOption[] = [
    {
        value: 'RO',
        label: 'Viewer'
    },
    {
        value: 'RW',
        label: 'Editor'
    },
    {
        value: 'AD',
        label: 'Full control'
    }
];

export const useWorkspaceACLUtils = () => {
    const everyoneGroup = useEveryoneGroup();

    const everyoneGroupId = everyoneGroup.data?.id;
    const currentUser = Auth.user?.name.toLowerCase().trim();

    const getDefaultACLClient = () => {
        if (!everyoneGroupId || !currentUser) {
            return [];
        }

        const userACL: AccessControlEntryModel = { permissions: ['AD'], subjectId: currentUser };
        const everyoneACL: AccessControlEntryModel = { permissions: ['RO'], subjectId: everyoneGroupId };

        return [everyoneACL, userACL];
    };

    const getDefaultACLServer = () => {
        if (!everyoneGroupId) {
            return [];
        }

        const defaultACLServer: AccessControlEntryModel = { permissions: ['AD'], subjectId: everyoneGroupId };

        return [defaultACLServer];
    };

    const isACLValid = (acl: AccessControlEntryModel[]) => {
        return acl.length > 0 && acl.find((ace) => ace.permissions.includes('AD')) != null;
    };

    const isDefaultServer = (acl: AccessControlEntryModel[]) => {
        if (!everyoneGroupId) {
            return false;
        }

        return acl.length === 1 && acl[0].subjectId === everyoneGroupId;
    };

    return {
        getDefaultACLServer,
        getDefaultACLClient,
        isACLValid,
        isDefaultServer
    };
};

export const getWorkspaceACLQueryKey = (workspace: Partial<Pick<Workspace, 'id'>>, defaultACL?: AccessControlEntryModel[]) => {
    return [...AccessControlQueryKeys.EntityACL(workspace.id ?? 'create-workspace'), defaultACL].filter(isDefined);
};
