import { Serialised } from '@squaredup/ids';
import { HealthState, stateStrings } from '@squaredup/monitoring';
import { DashboardType } from 'dashboard-engine/types/Dashboard';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import type { StatusRequestType } from 'pages/status/ui/StatusOverview';
import { oneMinute } from 'queries/constants';
import { useDashboardsForWorkspace } from 'queries/hooks/useDashboardsForWorkspace';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { flattenedDashboardsSortedByWorkspaceOrder } from 'queries/utils/dashboardSorted';
import { useMemo } from 'react';
import { useQuery, type UseQueryOptions } from 'react-query';
import { useDataStreamDefinitionsForWorkspace } from 'services/DataStreamDefinitionService';
import { ListWorkspaceHealthByIds, TileState } from 'services/HealthService';
import type { DashboardMonitors, Monitor } from './Monitoring';

type Props = {
    workspaceId: string | undefined | null;
    queryOptions?: UseQueryOptions<UseMonitorsData>;
};

type UseMonitorsData = {
    tilesByDashboard: DashboardMonitors[];
    monitors: (Monitor & { name: string; link: string; id: string; state: HealthState; type: StatusRequestType })[];
};

export const useMonitorsInWorkspace = ({ workspaceId, queryOptions }: Props) => {
    const { data: dataStreamsForWorkspace, isLoading: isLoadingDataStreams } = useDataStreamDefinitionsForWorkspace({
        workspace: workspaceId ?? undefined,
        queryOptions: {
            suspense: Boolean(queryOptions?.suspense) // Use of suspense needs to be consistent with all queries
        }
    });

    const { data: workspace, isLoading: isLoadingWorkspace } = useWorkspace(workspaceId, {
        suspense: queryOptions?.suspense
    });

    const { data: dashboards = [], isLoading: isLoadingDashboards } = useDashboardsForWorkspace(workspaceId, {
        enabled: Boolean(workspace),
        suspense: queryOptions?.suspense,
        select: flattenedDashboardsSortedByWorkspaceOrder(workspace?.data.properties?.dashboardIdOrder)
    });

    const { data: workspaceHealthStates, isLoading: isLoadingWorkspaceHealth } = useQuery(
        workspaceQueryKeys.state([workspaceId!], true),
        async () => ListWorkspaceHealthByIds([workspaceId!], true),
        {
            cacheTime: oneMinute,
            staleTime: oneMinute,
            enabled: Boolean(workspaceId),
            suspense: queryOptions?.suspense
        }
    );

    const data = useMemo(() => {
        if (!workspaceHealthStates) {
            return { tilesByDashboard: [], monitors: [] };
        }

        const { tileStates = [] } = workspaceHealthStates;

        // need to wire this up to keep requesting data so monitor states are correct
        const monitors: UseMonitorsData['monitors'] = tileStates.map((tileState) => {
            const displayName = getMonitorTitle(dashboards, tileState, dataStreamsForWorkspace);
            const dashboard = dashboards.find((d) => d.id === tileState.dashId);
            return {
                link: `/dashboard/${tileState.dashId}#tile-${tileState.tileId}`,
                name: displayName ?? 'Unnamed monitor',
                state: tileState.state as HealthState,
                id: `${tileState.dashId}/${tileState.tileId}`,
                tileId: tileState.tileId,
                dashId: tileState.dashId,
                lastChangedFrom: tileState.lastChangedFrom || stateStrings.unknown,
                lastEvaluated: new Date(tileState.lastEvaluated),
                lastChanged: tileState.lastChanged ? new Date(tileState.lastChanged) : undefined,
                dashboardDisplayName: dashboard?.displayName,
                dashboardFolderPath: dashboard?.folderPath,
                tileDisplayName: displayName,
                consecutiveFailures: tileState.consecutiveFailures ?? 0,
                nextEvaluation: tileState.nextEvaluation ? new Date(tileState.nextEvaluation) : undefined,
                reason: tileState.stateReason?.text,
                type: 'monitor'
            };
        });

        const tilesByDashboard: DashboardMonitors[] = dashboards.map(
            (d: { displayName: string; id: string; folderPath: string[] }) => ({
                dashId: d.id,
                folderPath: d.folderPath,
                displayName: d.displayName,
                monitoredTiles: monitors.filter((t) => t.dashId === d.id)
            })
        );

        return {
            tilesByDashboard: tilesByDashboard,
            monitors: monitors
        };
    }, [dashboards, dataStreamsForWorkspace, workspaceHealthStates]);

    return {
        data,
        isLoading: isLoadingDataStreams || isLoadingWorkspace || isLoadingDashboards || isLoadingWorkspaceHealth
    };
};

const getMonitorTitle = (
    dashboards: DashboardType[],
    tileState: TileState | undefined,
    dataStreams: Serialised<ProjectedDataStreamDefinitionEntity>[] | undefined
) => {
    if (!tileState) {
        return undefined;
    }

    if (tileState.tileName && tileState.tileName !== 'Untitled') {
        return tileState.tileName;
    }

    // fallback to using datastream if tile is unnamed
    const tiles = dashboards.find((dash) => dash.id === tileState.dashId)?.content?.contents;
    const tile = tiles?.find((t) => t.i === tileState.tileId);
    const dataStreamId = tile?.config?.dataStream?.id;
    const datastream = dataStreamId ? dataStreams?.find((ds) => ds.id === dataStreamId) : undefined;
    return datastream?.displayName ?? 'Unnamed Monitor';
};
