import stringify from 'fast-json-stable-stringify';
import { Workspace } from 'services/WorkspaceService';
import { ADMINISTRATION, LINKING, LIST, ROLLUP, STATE } from './common';

const WORKSPACES = 'workspaces';
const INCLUDE_TILE_STATES = 'includeTileStates';
const INCLUDE_DASHBOARD_NAMES = 'includeDashboardNames';
const KPIS = 'KPIS';
const AVATAR = 'avatar';

export const workspaceQueryKeys = {
    all: [WORKSPACES],
    list: [WORKSPACES, LIST],
    forAdministration: [WORKSPACES, ADMINISTRATION],
    states: [WORKSPACES, STATE],
    stateRollups: [WORKSPACES, STATE, ROLLUP],
    forLinking: (workspaceId?: string | null) => [WORKSPACES, LINKING, String(workspaceId)],
    kpis: (workspaceId?: string | null) => [WORKSPACES, KPIS, String(workspaceId)],
    state: (workspaceIds?: string[], includeTileStates = false, includeDashboardNames = false) => [
        WORKSPACES,
        STATE,
        stringify(workspaceIds?.sort()),
        ...(includeTileStates ? [INCLUDE_TILE_STATES] : []),
        ...(includeDashboardNames ? [INCLUDE_DASHBOARD_NAMES] : [])
    ],
    avatar: (id: Workspace['id']) => [WORKSPACES, AVATAR, id]
};
