import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig, isNoData } from '@squaredup/data-streams';
import { LoadingOverlay } from 'components/LoadingOverlay';
import LoadingSpinner from 'components/LoadingSpinner';
import NoDataPlaceholder from 'components/NoDataPlaceholder';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useTileContext } from 'contexts/TileContext';
import VisualisationContext from 'contexts/VisualisationContext';
import { DataCriteriaList } from 'dashboard-engine/dataStreams/DataCriteriaList';
import { DataStreamErrors } from 'dashboard-engine/dataStreams/DataStreamErrors';
import { matchVisualisations } from 'dashboard-engine/dataStreams/VisualisationMatching';
import { DataStreamVisualisation } from 'dashboard-engine/types/Visualisation';
import { hasTable } from 'dashboard-engine/util/getIsDataStreamConfigured';
import { parseMonitorConfig } from 'dashboard-engine/util/monitoring';
import { useFlag } from 'lib/useFlag';
import { dashboardHasNoVariableObjectsSelected } from 'pages/dashboard/components/utils/variableDropdown';
import { useMemo } from 'react';
import { useDataStreamType } from 'ui/editor/dataStream/TileEditor/hooks/useDataStreamType';
import { useScopes } from 'ui/editor/dataStream/TileEditor/hooks/useScopes';
import { DATASTREAM_MESSAGES } from 'ui/tile/TileWarnings';
import { useDataStreamConfig } from '../hooks/useDataStreamConfig';
import { useResolvedConfig } from '../hooks/useResolvedConfig';
import { visualisationOptionsRepo, visualisationsRepo } from '../repositories/visualisationsRepo';

interface DataStreamBaseTileProps {
    config: DataStreamBaseTileConfig;
}

const DataStreamBaseTile: React.FC<DataStreamBaseTileProps> = ({ config }) => {
    const { data, isLoading, isFetched, isPreviousData, error, isConfigured } = useDataStreamConfig(
        config,
        {},
        'dashboard'
    );
    const dashboardContext = useDashboardContext();
    const { preview, health, onChange } = useTileContext();

    const tileScope = config.scope;
    const { data: scopesData, isLoading: isLoadingScopes, isFetching: isFetchingScopes } = useScopes();
    const checkTileScope = !isFetchingScopes && tileScope != null && 'scope' in tileScope;
    const doesScopeExist = scopesData?.some((scope) => checkTileScope && scope.id === tileScope.scope);

    const { dataStreamType } = useDataStreamType(config);

    const isTileWithDataStream = Boolean(config?.dataStream);
    const isSqlAndTableSelected = dataStreamType && hasTable(config?.dataStream);

    const updateVisualisationConfig = (content: DataStreamBaseTileConfig) => {
        if (!dashboardContext.editing || config?._type !== 'tile/data-stream') {
            return;
        }

        const configType = config?.visualisation?.type;
        if (!configType) {
            return;
        }

        const existingConfig = config?.visualisation?.config?.[configType] ?? {};

        onChange?.({
            ...config,
            visualisation: {
                config: {
                    [configType]: {
                        ...existingConfig,
                        ...content
                    }
                },
                type: configType
            }
        });
    };

    const embedVisualisationFlagEnabled = useFlag('embedVisualisation');

    const hasData = !isNoData(data);

    const [visualisationType, Visualisation, dataMatch, defaultConfig] = useMemo(() => {
        const visualizationConfig =
            config.visualisation?.type && config?.visualisation?.config?.[config.visualisation?.type];

        const validVisualisations = matchVisualisations(data, visualizationConfig, embedVisualisationFlagEnabled);

        if (typeof config?.visualisation?.type === 'string') {
            if (preview || validVisualisations.includes(config?.visualisation?.type)) {
                return [
                    config.visualisation.type,
                    visualisationsRepo.get(config.visualisation.type) as DataStreamVisualisation<any>,
                    visualisationOptionsRepo.get(config.visualisation.type).matchesData(data, visualizationConfig),
                    visualisationOptionsRepo.get(config.visualisation.type).getDefaultConfig?.(data) || {}
                ];
            }
        }

        if (hasData) {
            const [bestVisualisation] = validVisualisations;
            return [bestVisualisation, visualisationsRepo.get(bestVisualisation) as DataStreamVisualisation<any>];
        }

        return [];
    }, [data, embedVisualisationFlagEnabled, config, preview, hasData]);

    const visualisationConfig = useResolvedConfig(
        (visualisationType && config?.visualisation?.config?.[visualisationType]) || defaultConfig || {},
        dashboardContext,
        data
    );

    const noVariableObjectsSelected = dashboardHasNoVariableObjectsSelected(dashboardContext.variables);

    if (!doesScopeExist && checkTileScope && tileScope.scope && scopesData) {
        return (
            <div
                className='flex flex-col justify-center w-full h-full text-center whitespace-normal'
                data-testid='dataStreamBaseTileError'
            >
                <DataStreamErrors
                    error={DATASTREAM_MESSAGES.SCOPE_NOT_FOUND}
                    showDetailedErrors={dashboardContext.showDetailedErrors}
                />
            </div>
        );
    }

    if (!isTileWithDataStream || (dataStreamType.supportsDatasets && !isSqlAndTableSelected)) {
        return <NoDataPlaceholder message='No data stream selected.' />;
    }

    const hasScopeIfRequired =
        config?.scope ||
        !dataStreamType.supportsScope ||
        (!dataStreamType.requiresScope && config?.dataStream?.pluginConfigId != null);

    if (!hasScopeIfRequired) {
        return <NoDataPlaceholder message='No objects selected.' />;
    }

    // Show a message if the tile has not yet been configured
    if (dashboardContext.editing && preview && !isConfigured) {
        return <NoDataPlaceholder />;
    }

    // Show a message if the tile has not yet been configured
    if (dashboardContext.editing && preview && !isConfigured) {
        return (
            <div className='flex items-center justify-center h-full'>
                <span className='text-textSecondary'>Configure this tile above to get started...</span>
            </div>
        );
    }

    if (noVariableObjectsSelected && config.variables?.length) {
        return (
            <NoDataPlaceholder
                message='No objects selected for variable.'
                icon={<FontAwesomeIcon icon={faBarsFilter} fixedWidth={true} className='mr-2 text-2xl' />}
            />
        );
    }

    if (isLoading || isLoadingScopes) {
        return (
            <div className='absolute top-0 w-full h-full pt-5 text-center'>
                <LoadingSpinner />
            </div>
        );
    }

    // When an unsupported timeframe is met display placeholder
    if (isFetched && error === null && dataMatch === undefined) {
        return <NoDataPlaceholder />;
    }

    return !error && dataMatch?.success ? (
        <>
            {Visualisation && (
                <LoadingOverlay loading={isPreviousData}>
                    <VisualisationContext.Provider value={{ updateVisualisationConfig }}>
                        <Visualisation
                            key={'visualisation'} // ensure we don't lose state when re-rendering
                            data={data}
                            config={visualisationConfig}
                            monitorConditions={parseMonitorConfig(config?.monitor)}
                            healthState={health?.state}
                            shapingConfig={{
                                filter: config.dataStream?.filter,
                                group: config.dataStream?.group,
                                sort: config.dataStream?.sort
                            }}
                        />
                    </VisualisationContext.Provider>
                </LoadingOverlay>
            )}
            {isFetched && !Visualisation && <NoDataPlaceholder />}
        </>
    ) : (
        <div
            className='flex flex-col justify-center w-full h-full text-center whitespace-normal'
            data-testid='dataStreamBaseTileError'
        >
            {error && <DataStreamErrors error={error} showDetailedErrors={dashboardContext.showDetailedErrors} />}
            {dataMatch && !dataMatch?.success && (
                <DataCriteriaList key={config?.visualisation?.type} criteria={dataMatch.criteria} />
            )}
        </div>
    );
};

export default DataStreamBaseTile;
