import { Divider } from '@/components/Divider';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Popover from '@radix-ui/react-popover';
import { useDOMElement } from 'components/hooks/useDOMElement';
import Tooltip from 'components/tooltip/Tooltip';
import { useState, type FC, type ReactNode } from 'react';

export type TileWarning = {
    heading?: string;
    message: ReactNode;
    raw?: boolean;
};

export type TileWarningGroup = {
    heading?: string;
    description?: ReactNode;
    warnings: TileWarning[];
};

interface TileWarningPopoverProps {
    /**
     * Customises the summary shown when hovering on the warning icon.
     */
    summary?: ReactNode;
    warnings: (TileWarning | TileWarningGroup)[];
}

const Warning: FC<{ warning: TileWarning; headingClassName?: string }> = ({ warning, headingClassName }) => {
    return (
        <div className='flex flex-col space-y-2 items-left text-textPrimary'>
            {warning.heading && <h5 className={headingClassName}>{warning.heading}</h5>}
            {!warning.raw ? (
                <p>{warning.message}</p>
            ) : (
                <pre className='whitespace-normal border-2 px-sm py-xs bg-tagBackground rounded-input leading-input border-tileOutline'>
                    {warning.message}
                </pre>
            )}
        </div>
    );
};

export const TileWarningPopover: React.FC<TileWarningPopoverProps> = ({ warnings, summary }) => {
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const menuContainer = useDOMElement('dialogContent');

    return (
        <Tooltip
            disabled={popupIsOpen}
            title={summary ?? `${warnings.length} data stream warning${warnings.length > 1 ? 's' : ''}`}
        >
            <Popover.Root onOpenChange={setPopupIsOpen}>
                <Popover.Trigger asChild>
                    <button>
                        <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className='z-10 cursor-pointer text-statusWarningPrimary'
                        />
                    </button>
                </Popover.Trigger>

                <Popover.Portal container={menuContainer}>
                    <Popover.Content sideOffset={5} align='end'>
                        <div className='p-5 border rounded-input bg-componentBackgroundSecondary border-outlinePrimary max-w-[100vw]'>
                            <div className='max-w-3xl pr-2 space-y-4 overflow-auto max-h-96 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary text-textPrimary'>
                                {warnings.map((warning, i) => {
                                    if ('warnings' in warning) {
                                        return (
                                            <div key={`${warning.heading}${i}`}>
                                                {warning.heading && (
                                                    <h5 className='mb-2 font-semibold'>{warning.heading}</h5>
                                                )}
                                                {warning.description}
                                                {warning.warnings.map((w) => (
                                                    <Warning warning={w} />
                                                ))}
                                                <Divider className='mt-4' />
                                            </div>
                                        );
                                    }

                                    return (
                                        <>
                                            <Warning
                                                key={`${warning.heading}${i}`}
                                                warning={warning}
                                                headingClassName='font-semibold'
                                            />
                                            <Divider className='mt-4' />
                                        </>
                                    );
                                })}
                            </div>
                            <div className='flex justify-between pt-2'>
                                <a
                                    href='https://squaredup.com/cloud/TroubleshootingTiles'
                                    target='_blank'
                                    rel='noreferrer'
                                    className='text-sm text-textLink'
                                >
                                    Learn more about tile warnings.
                                </a>
                            </div>
                        </div>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </Tooltip>
    );
};
