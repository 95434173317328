import LoadingSpinner from 'components/LoadingSpinner';
import { SearchIcon } from 'components/SearchIcon';
import Button from 'components/button/Button';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { baseDataStreamConfig } from 'dashboard-engine/constants';
import stringify from 'fast-json-stable-stringify';
import Fuse from 'fuse.js';
import { trackEventDebounced } from 'lib/analytics';
import { useFlag } from 'lib/useFlag';
import { CreateEditScope } from 'pages/scope/CreateEditScope';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { variableObjectQueryKeys } from 'queries/queryKeys/variableObjectKeys';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDatasetContext } from '../../contexts/DatasetContext';
import TileEditorDataStreamFilterContext from '../../contexts/TileEditorDataStreamFilterContext';
import { useTileEditorStepsContext } from '../../contexts/TileEditorStepsContext';
import { SearchBar } from '../SearchBar';
import { DataSourceFilterDropdown } from '../dataStream/DataSourceFilterDropdown';
import { DataStreamVirtualList } from '../dataStream/DataStreamVirtualList';
import { ScopeFilterDropdown } from '../dataStream/ScopeFilterDropdown';
import { TypeFilterDropdown } from '../dataStream/TypeFilterDropdown';
import { useDataStreamFilters } from '../hooks/useDataStreamFilters';
import { AddDataSourceModal } from './DataSourceModal/AddDataSourceModal';
import { DataStreamTagsFilter } from './DataStreamTagsFilter';

export const DataStreamStep = ({
    dataStreamFilters
}: {
    dataStreamFilters: ReturnType<typeof useDataStreamFilters>;
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showAddDataSourceModal, setShowAddDataSourceModal] = useState(false);
    const [scopeModalOpen, setScopeModalOpen] = useState(false);
    const [editingScope, setEditingScope] = useState();
    const { variables = [] } = useDashboardContext();
    const { nextStep } = useTileEditorStepsContext();
    const { config, setConfig } = useDatasetContext();
    const queryClient = useQueryClient();
    const { workspace, isGlobal } = useDataStreamWorkspaceContext();
    const dataStreamTagsFlagEnabled = useFlag('dataStreamTags');

    const {
        dataStreams,
        plugins,
        scopes,
        objectScopes,
        tags,
        selectedObjectScope,
        selectedScopeId,
        selectedDataSourceId,
        selectedTags,
        isLoading,
        isLoadingScopeDataStreamMatches,
        setSelectedDataSourceId,
        setSelectedObjectScope,
        setSelectedScopeId,
        setSelectedTags
    } = dataStreamFilters;

    const fuse = useMemo(() => {
        if (isLoading) {
            return undefined;
        }

        return new Fuse(dataStreams!, {
            keys: [
                { name: 'displayNameFull', weight: 10 },
                { name: 'description', weight: 2 },
                { name: 'pluginConfigName', weight: 1 },
                { name: 'definition.matchesTypes', weight: 2 },
                { name: 'definition.tags', weight: 2 },
                { name: 'definition.matches.sourceType.value', weight: 2 }
            ],
            threshold: 0.4
        });
    }, [dataStreams, isLoading]);

    const dataStreamsFilteredBySearch = searchTerm ? fuse?.search(searchTerm).map(({ item }) => item) : dataStreams;

    useEffect(() => {
        if (
            variables.length > 0 &&
            stringify(config) ===
                stringify({
                    description: '',
                    title: '',
                    ...baseDataStreamConfig
                })
        ) {
            // Set variables if the tile is new
            setConfig((currentConfig) => ({
                ...currentConfig,
                variables: variables.map((v) => v.id)
            }));
            setSelectedScopeId(variables[0].scopeId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearch = (query: string) => {
        trackEventDebounced('Data Stream Searched For', { query });
        setSearchTerm(query);
    };

    const toggleTag = (tag: string) => {
        let newTags = [...selectedTags];
        if (newTags.includes(tag)) {
            newTags = newTags.filter((t) => t !== tag);
        } else {
            newTags = [...newTags, tag];
        }
        setSelectedTags(newTags);
    };

    if (isLoading) {
        return (
            <div className='flex items-center justify-center h-full'>
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div className='flex flex-1 h-full min-w-0 min-h-0 space-x-6 py-7'>
            <TileEditorDataStreamFilterContext.Provider value={{ selectedScopeId }}>
                <div className='flex flex-col flex-1 h-full min-w-0 min-h-0'>
                    <div className='flex pl-6 pr-5 mb-3 gap-x-4'>
                        <SearchBar placeholder='Search for data streams...' onChange={onSearch} />

                        <DataSourceFilterDropdown
                            sources={plugins}
                            selectedDataSourceId={selectedDataSourceId}
                            setSelectedDataSourceId={setSelectedDataSourceId}
                            {...(!isGlobal && { onAddDataSource: () => setShowAddDataSourceModal(true) })}
                        />

                        <TypeFilterDropdown
                            types={objectScopes}
                            selectedType={selectedObjectScope}
                            setSelectedType={setSelectedObjectScope}
                        />

                        {!isGlobal && (
                            <ScopeFilterDropdown
                                scopes={scopes}
                                selectedScopeId={selectedScopeId}
                                setSelectedScopeId={setSelectedScopeId}
                                onAddScope={() => setScopeModalOpen(true)}
                                onEditScope={(scope) => {
                                    setEditingScope(scope);
                                    setScopeModalOpen(true);
                                }}
                            />
                        )}
                    </div>

                    {dataStreamTagsFlagEnabled && tags.length > 0 && (
                        <DataStreamTagsFilter tags={tags} selectedTags={selectedTags} toggleTag={toggleTag} />
                    )}

                    {isLoadingScopeDataStreamMatches ? (
                        <div className='w-full h-full pl-6 pr-5 pt-2'>
                            <LoadingSpinner />
                        </div>
                    ) : dataStreamsFilteredBySearch?.length === 0 ? (
                        <div className='flex h-full place-content-center text-textPrimary'>
                            <div className='m-auto text-center h-fit w-fit'>
                                <SearchIcon className='m-auto w-fit h-fit' />
                                <div className='-mt-8'>
                                    <p className='mb-2'>Couldn't find what you are looking for?</p>
                                    <Button variant={'link'} onClick={() => setShowAddDataSourceModal(true)}>
                                        Add a data source
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // Disable the sort to avoid losing the search order if a search term is present
                        <DataStreamVirtualList
                            dataStreams={dataStreamsFilteredBySearch}
                            allowSort={!searchTerm}
                            selectedTags={selectedTags}
                            toggleTag={toggleTag}
                        />
                    )}

                    <Button disabled={!config.dataStream} className='self-end mt-5 mr-4' onClick={nextStep}>
                        Next
                    </Button>
                </div>
            </TileEditorDataStreamFilterContext.Provider>

            {scopeModalOpen && (
                <CreateEditScope
                    scope={editingScope}
                    onClose={(modifiedScope) => {
                        setScopeModalOpen(false);
                        setEditingScope(undefined);
                        if (modifiedScope && workspace) {
                            queryClient.invalidateQueries(scopeQueryKeys.workspace(workspace));
                            queryClient.removeQueries(variableObjectQueryKeys.all);
                            setSelectedScopeId(modifiedScope.id);
                        }
                    }}
                />
            )}

            {showAddDataSourceModal && (
                <AddDataSourceModal
                    workspaceId={workspace}
                    onSelectedDataSource={(dataSourceId?: string) => {
                        setShowAddDataSourceModal(false);
                        // Remove the search to avoid getting stuck on the empty page
                        setSearchTerm('');
                        if (dataSourceId) {
                            setSelectedDataSourceId(dataSourceId);
                        }
                    }}
                />
            )}
        </div>
    );
};
