import { cn } from '@/lib/cn';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    ColumnDef,
    SortingState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, Fragment, useMemo, useState } from 'react';
import { ApplicationTableActionColumn, type ApplicationTableActionColumnProps } from './ApplicationTableActionColumn';
import { ApplicationTableGlobalFilter } from './ApplicationTableGlobalFilter';
import { Action, ApplicationTableConfig } from './types';

export interface ApplicationTableProps<T, U> extends Pick<ApplicationTableActionColumnProps<T>, 'hiddenActions'> {
    config: ApplicationTableConfig<T>;
    data: T[] | undefined;
    columns: ColumnDef<T, U>[];
    classNames?: {
        actionColumn: string;
        tableHeader?: string;
    };
    getRowId?: (row: T) => string;
}

export const ApplicationTable =
    <T, U>(): FC<ApplicationTableProps<T, U>> =>
    (props) =>
        ApplicationTableInternal(props);

const ApplicationTableInternal = <T, U>({
    config,
    data = [],
    columns,
    classNames,
    hiddenActions,
    getRowId
}: ApplicationTableProps<T, U>) => {
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [sorting, setSorting] = useState<SortingState>([]);

    const defaultColumn = {
        minSize: 50,
        enableSorting: !config.disableSortBy,
        enableSortingRemoval: true,
        enableGlobalFilter: true
    };

    const searchWidth = getSearchWidth(columns.at(-1)!);

    const memoizedData = useMemo(() => data, [data]);

    const memoizedColumns = useMemo(() => {
        const actions = config.actions
            ? config.actions.filter((a: Action) => memoizedData?.some((row) => (a.visible && a.visible(row)) ?? true))
            : [];

        return [
            ...columns,
            {
                id: 'actions',
                header: () => {
                    return !config.hideSearch ? (
                        <ApplicationTableGlobalFilter
                            globalFilter={globalFilter}
                            setGlobalFilter={config.searchFunction ?? setGlobalFilter}
                        />
                    ) : (
                        <></>
                    );
                },
                cell: ({ row }) => {
                    return (
                        <ApplicationTableActionColumn
                            className={classNames?.actionColumn}
                            actions={actions}
                            row={row}
                            hiddenActions={hiddenActions}
                        />
                    );
                },
                accessorKey: '',
                enableSorting: false,
                size: getActionsWidth(actions.length, searchWidth, config.hideSearch),
                minSize: 0
            }
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, config.actions]);

    const table = useReactTable({
        data: memoizedData,
        columns: memoizedColumns,
        defaultColumn,
        state: {
            globalFilter: globalFilter,
            sorting: sorting
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getColumnCanGlobalFilter: () => true,
        getFilteredRowModel: getFilteredRowModel(),
        getRowCanExpand: config.getRowCanExpand,
        getExpandedRowModel: getExpandedRowModel(),
        getRowId
    });

    const totalTableWidth = memoizedColumns.reduce((p, c) => p + (c.size || 0), 0);

    return (
        <table
            className='inline-flex flex-col flex-1 min-h-0 overflow-auto leading-normal table-scroll-overflow'
            onScroll={(e) => config.scrollFunction?.(e.target as HTMLDivElement)}
            data-testid={config.dataTestId ?? 'table'}
            style={{ tableLayout: 'auto', width: '100%' }}
            role='table'
        >
            <thead
                className={cn(
                    'sticky top-0 z-40 flex min-w-full rounded-t shrink-0 border-x border-dividerTertiary',
                    classNames?.tableHeader
                )}
            >
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                        className='relative flex items-center justify-between rounded-t border-y bg-tagBackground border-dividerTertiary'
                        key={headerGroup.id}
                        style={{ flex: '1 0 auto' }}
                        role='row'
                    >
                        {headerGroup.headers.map((header, i) => (
                            <th
                                className={cn(
                                    'flex px-4 py-3 font-semibold text-left break-word last:flex-grow group last:px-0 last:sticky last:right-0 last:py-0 last:align-middle last:pointer-events-none first:rounded-t last:rounded-t',
                                    !header.column.getCanSort() && 'pointer-events-none'
                                )}
                                key={header.id}
                                style={{
                                    width: header.column.getSize(),
                                    minWidth: table._getDefaultColumnDef().minSize,
                                    flexGrow: header.column.getSize(),
                                    flexShrink: 0,
                                    flexBasis: 'auto'
                                }}
                            >
                                <span
                                    className='relative w-full cursor-pointer group'
                                    title={
                                        i !== headerGroup.headers.length - 1 &&
                                        header.column.getCanSort() &&
                                        !config.disableSortBy
                                            ? 'Sort by'
                                            : ''
                                    }
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(header.column.columnDef.header, header.getContext())}
                                    {header.column.getCanSort() && (
                                        <FontAwesomeIcon
                                            icon={
                                                (header.column.getIsSorted() as string) === 'desc'
                                                    ? faChevronDown
                                                    : faChevronUp
                                            }
                                            className={`ml-2 mt-1 absolute ${
                                                header.column.getIsSorted()
                                                    ? 'visible'
                                                    : 'invisible group-hover:visible text-textSecondary'
                                            }`}
                                        />
                                    )}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody className='min-w-full min-h-0 text-textTable' style={{ minWidth: totalTableWidth }}>
                {table.getRowModel().rows.length > 0 ? (
                    table.getRowModel().rows.map((row) => {
                        return (
                            <Fragment key={row.id}>
                                <tr
                                    className='relative flex border-b border-dividerTertiary hover:bg-tableHover group'
                                    data-testid='tableRow'
                                    role='row'
                                >
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td
                                                className='self-center px-4 py-3 break-words last:self-stretch last:flex-grow last:px-0 last:sticky last:right-0 last:py-0 last:pointer-events-none'
                                                key={`${row.id}-${cell.id}`}
                                                style={{
                                                    width: cell.column.getSize(),
                                                    minWidth: table._getDefaultColumnDef().minSize,
                                                    flexGrow: cell.column.getSize(),
                                                    flexShrink: 0,
                                                    flexBasis: 'auto'
                                                }}
                                                role='cell'
                                            >
                                                {config.splitArrayCellsIntoRows && Array.isArray(cell.getValue())
                                                    ? (cell.getValue() as Array<T>).map((value, valueIndex) => {
                                                          return (
                                                              <div
                                                                  key={valueIndex}
                                                                  className={cn(
                                                                      // eslint-disable-next-line max-len
                                                                      valueIndex <
                                                                          (cell.getValue() as Array<T>).length - 1 &&
                                                                          'border-b border-dividerTertiary pb-2 mb-2'
                                                                  )}
                                                              >
                                                                  {typeof value === 'object'
                                                                      ? Object.entries(value as object).map(
                                                                            ([k, v], index) => {
                                                                                return (
                                                                                    <div key={index} className='flex'>
                                                                                        <em className='mr-1'>{k}: </em>{' '}
                                                                                        {v.toString()}
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )
                                                                      : value}
                                                              </div>
                                                          );
                                                      })
                                                    : flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        );
                                    })}
                                </tr>
                                <AnimatePresence>
                                    {row.getIsExpanded() && config.renderExpandedRow != null && (
                                        <motion.tr
                                            key='tableRowExpansion'
                                            initial={{ height: 0 }}
                                            animate={{ height: 'fit-content' }}
                                            exit={{ height: 0 }}
                                            transition={{ duration: 0.3 }}
                                            className='relative flex overflow-hidden border-b border-dividerTertiary'
                                            data-testid='tableRowExpansion'
                                            role='row'
                                        >
                                            <td colSpan={row.getVisibleCells().length} className='grow' role='cell'>
                                                {config.renderExpandedRow({ row })}
                                            </td>
                                        </motion.tr>
                                    )}
                                </AnimatePresence>
                            </Fragment>
                        );
                    })
                ) : (
                    <div
                        className='relative px-4 py-3 text-center border-b text-textIncomplete border-dividerTertiary'
                        data-testid='tableRow'
                        style={{ minWidth: totalTableWidth }}
                    >
                        {data.length > 0
                            ? `${config.noSearchResultsMessage || 'No search results were found.'}`
                            : `${config.noDataMessage || 'No data to display.'}`}
                    </div>
                )}
            </tbody>
        </table>
    );
};

function getSearchWidth<T, U>(lastColumn: ColumnDef<T, U>) {
    const lastHeaderWidth = lastColumn.size || 0;
    const lastHeader = lastColumn.header;
    const lastHeaderWidthEstimate =
        typeof lastHeader === 'string'
            ? lastHeader?.length * 16 + (lastColumn.enableSorting ? 12 : 0) // Character width estimate + sort arrow width
            : 0;
    const lastHeaderBlankWidthEstimate = Math.max(lastHeaderWidth - lastHeaderWidthEstimate, 0); // Width estimate could be greater than the set width, so make sure we don't go negative
    const searchWidth = Math.max(240 - lastHeaderBlankWidthEstimate, 66); // Blank space could be bigger than search bar, so make sure we don't go negative
    return searchWidth;
}

function getActionsWidth(actionsLength: number, searchWidth: number, hideSearch: boolean | undefined) {
    return Math.max(
        actionsLength > 0
            ? 14 + 7 + 17.5 * actionsLength + 3.5 * (actionsLength - 1) + 14 // Total with padding, margins and button widths
            : 0,
        !hideSearch ? searchWidth : 0
    );
}
