import LoadingSpinner from 'components/LoadingSpinner';
import { LayoutFlow } from 'components/map/components/LayoutFlow';
import { NetworkMapStoreProvider } from 'components/map/context/NetworkMapStoreContext';
import { useGlobalMapData } from 'components/map/data/useGlobalMapData';
import { setNodesAndEdgeVisibility } from 'components/map/data/utils/setNodesAndEdgeVisibility';
import { LayoutTypes } from 'components/map/layout/types';
import { ReactFlowProvider } from 'reactflow';

export const TenantNetwork: React.FC = () => {
    const { data, isLoading } = useGlobalMapData();

    const { nodes: graphNodes, edges: graphEdges } = data ?? {};
    const expandedNodeIds = graphNodes?.map(({ id }) => id) ?? [];

    const { nodes, edges } = setNodesAndEdgeVisibility(
        graphNodes ?? [], 
        graphEdges ?? [], 
        expandedNodeIds,
        expandedNodeIds,
        new Map<string, string[]>(),
        []
    );
    
    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div 
            id='globalMap'
            className='relative w-full h-full'
        >
            <div className='absolute inset-0 w-full'>
                <NetworkMapStoreProvider 
                    mapId='globalMap'
                    layoutType={LayoutTypes.hierarchyVertical}
                    nodes={nodes ?? []} 
                    edges={edges ?? []} 
                    graphNodes={graphNodes ?? []} 
                    graphEdges={graphEdges ?? []} 
                    background='backgroundSecondary'
                    expandedNodeIds={expandedNodeIds}
                    pinnedNodeIds={expandedNodeIds}
                    ungroupedNodeIds={[]}
                    pinnedGroupsWithMemberIds={new Map<string, string[]>()}
                >
                    <ReactFlowProvider key='mapReactFlow'>
                        <LayoutFlow
                            key='mapReactFlowLayout'
                            hideCollapseButton={true}
                        />
                    </ReactFlowProvider>
                </NetworkMapStoreProvider>
            </div>
        </div>
    );
};