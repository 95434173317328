import { useCallback } from 'react';
import { ReactNode } from 'react-markdown';
import { useNavigate } from 'react-router';
import ReactFlow, { Controls } from 'reactflow';
import { useNetworkEdges, useNetworkEdgesChange, useNetworkNodes, useNetworkNodesChange } from '../context/NetworkMapStoreContext';
import { PinnableNode } from '../data/types';
import { useAnimateLayout } from '../hooks/useAnimateLayout';
import { useHandleFixedNodePosition } from '../hooks/useHandleFixedNodePosition';
import { NodeAction, useHandleNodeAction } from '../hooks/useHandleNodeAction';
import { edgeTypes } from '../layout/edges/edgeTypes';
import { nodeTypes } from '../layout/nodes/nodeTypes';
import { CollapseAllButton } from './CollapseAllButton';
import { ArrowHeadMarkers } from './edges/ArrowHeadMarkers';
import { LayoutButton } from './LayoutButton';
import { GROUP_NODE } from './nodes/GroupNode';
import { KPI_NODE } from './nodes/KPINode';
import { ORGANISATION_NODE } from './nodes/OrganisationNode';
import { DefaultControls } from './nodeToolbar/DefaultControls';

interface LayoutFlowProps {
    controls?: ReactNode;
    hideCollapseButton?: boolean;
}

export const LayoutFlow = ({ controls, hideCollapseButton }: LayoutFlowProps) => {
    const navigate = useNavigate();

    const nodes = useNetworkNodes();
    const edges = useNetworkEdges();
    const onNodesChange = useNetworkNodesChange();
    const onEdgesChange = useNetworkEdgesChange();
    const handleNodeAction = useHandleNodeAction();
    const { fixPosition } = useHandleFixedNodePosition();

    useAnimateLayout();

    const handleNodeClick = useCallback((_, node: PinnableNode) => {
        if (node.type === ORGANISATION_NODE) {
            return;
        }

        // If clicking a KPI node, navigate to the workspace
        if (node.type === KPI_NODE && node.data.workspaceId) {
            return navigate(`/workspace/${node.data.workspaceId}`);
        }

        const isGroupNode = node.type === GROUP_NODE;
        
        if (
            (node.data.pinned && !node.data.expanded) ||
            (!node.data.pinned && node.data.expanded) || 
            (!node.data.pinned && !node.data.expanded && !node.data.hiddenConnections)
        ) {
            return handleNodeAction(NodeAction.pin, { nodeIds: [node.id] });
        }

        if ((node.data.hiddenConnections && !node.data.expanded) || node.data.expanded) {
            handleNodeAction(
                isGroupNode ? NodeAction.pinAndExpandGroup : NodeAction.pinAndExpand, 
                { nodeIds: [node.id], ...isGroupNode && { sourceNodeIds: node.data.sourceNodeIds } }
            );
        }
    }, [handleNodeAction, navigate]);

    const handleNodeDrag = useCallback((_, node) => fixPosition(node.id), [fixPosition]);

    return (
        <>
            <ArrowHeadMarkers />
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                minZoom={0.1}
                proOptions={{ hideAttribution: true }}
                elevateNodesOnSelect={true}
                nodesConnectable={false}
                selectionOnDrag={false}
                onNodeClick={handleNodeClick}
                onNodeDrag={handleNodeDrag}
            >
                <Controls 
                    showZoom={false}
                    showFitView={false}
                    showInteractive={false}
                >
                    {controls}
                    <DefaultControls />
                    <LayoutButton />
                    {!hideCollapseButton && <CollapseAllButton />}
                </Controls>
            </ReactFlow>
        </>
    );
};