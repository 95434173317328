import type { KPIValue } from 'dynamo-wrapper';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListWorkspaceHealthByIds } from 'services/HealthService';
import { List as ListKPIs } from 'services/KPIService';

type QueryReturnType = Array<KPIValue>;

export const useWorkspaceKpis = <T = QueryReturnType>(
    workspaceId?: string | null,
    options?: Omit<UseQueryOptions<QueryReturnType, unknown, T, string[]>, 'select'>
) => {
    return useQuery({
        queryKey: workspaceQueryKeys.kpis(workspaceId),
        queryFn: async () => {
            const kpis = await ListKPIs(workspaceId!, undefined, { includeValues: true });
            const workspaceHealth = await ListWorkspaceHealthByIds([workspaceId!], true, true);

            const kpisWithState = kpis
                .map((kpi) => {
                    const tile = workspaceHealth?.tileStates?.find(
                        (state) => state.dashId === kpi.dashboardId && state.tileId === kpi.tileId
                    );

                    return { ...kpi, status: tile?.state ?? 'unknown' };
                })
                .sort((a, b) => a.name.localeCompare(b.name));

            return kpisWithState;
        },
        ...{
            enabled: (options?.enabled === undefined || options.enabled) && Boolean(workspaceId),
            ...options
        }
    });
};
