import Text from '@/components/Text';
import { Serialised } from '@squaredup/ids';
import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import Button from 'components/button/Button';
import { useDashboardContext } from 'contexts/DashboardContext';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import stringify from 'fast-json-stable-stringify';
import { DashboardVariableDropdown } from 'pages/dashboard/components/DashboardVariableDropdown';
import { useState } from 'react';
import EasyEdit from 'react-easy-edit';
import { useQueryClient } from 'react-query';
import {
    DATA_STREAM_DEFINITIONS_FOR_WORKSPACE,
    Get as getDataStreamDefinition
} from 'services/DataStreamDefinitionService';
import { useCurrentWorkspaceId } from 'services/WorkspaceUtil';
import EasyEditOverflowWrapper from 'ui/tile/EasyEditOverflowWrapper';
import { useTileEditorContext } from '../contexts/TileEditorContext';
import { DataStreamTileEditorTileOptions } from './DataStreamTileEditorTileOptions';
import { AnalyticsToggle } from './analytics/AnalyticsToggle';
import { useTileEditorSave } from './hooks/useTileEditorSave';
import { merge } from 'lodash';

export const DataStreamTileEditorHeader: React.FC = () => {
    const { savedTileConfig, tileConfig, containsError, setTileConfig, onClose } = useTileEditorContext();
    const [confirmDiscard, setConfirmDiscard] = useState(false);
    const queryClient = useQueryClient();
    const currentWorkspaceID = useCurrentWorkspaceId();

    const { variables = [] } = useDashboardContext();

    const saveTile = useTileEditorSave();

    // Use stable stringify to compare the tile's saved config to the editor's in-progress config
    const isEdited = stringify({ ...savedTileConfig, _type: 'tile/data-stream' as const }) !== stringify(tileConfig);

    const setConfigProperty = (field: string, v: string) => {
        setTileConfig((currentConfig) => ({ ...currentConfig, [field]: v }));
    };

    const onSave = async () => {
        let title = tileConfig.title;
        const dataStreamId = tileConfig.dataStream?.id;

        // If the saved config already has a data stream, we don't want to overwrite the title if it's empty.
        // Otherwise we assume this a new tile and if there is no title set, automatically set it to the name
        // of the selected data stream.
        if (!savedTileConfig.dataStream && !title && dataStreamId) {
            // See if we've got the data stream definition already in the cache
            const dataStreamDefinitions = queryClient.getQueryData<Serialised<ProjectedDataStreamDefinitionEntity>[]>([
                DATA_STREAM_DEFINITIONS_FOR_WORKSPACE,
                currentWorkspaceID
            ]);
            let dataStreamDefinition = dataStreamDefinitions?.find((d) => d.id === dataStreamId);

            // If we can't find it in the cache, fetch the definition
            if (!dataStreamDefinition) {
                dataStreamDefinition = await getDataStreamDefinition(dataStreamId);
            }
            if (dataStreamDefinition) {
                title = dataStreamDefinition.displayName;
            }
        }
        return saveTile({
            ...tileConfig,
            dataStream: merge(
                tileConfig.dataStream,
                tileConfig.dataStreamDraft
            ),
            title
        });
    };

    return (
        <>
            <header
                className='flex items-center px-6 py-6 gap-x-xxl bg-componentBackgroundSecondary text-textPrimary'
                data-theme='dark'
            >
                <div className='w-full space-y-2 overflow-hidden whitespace-nowrap text-ellipsis'>
                    <Text.H2>
                        <EasyEdit
                            type='text'
                            value={tileConfig.title || undefined}
                            placeholder={!tileConfig.title ? 'Click to add a title' : ''}
                            onSave={(v: string) => setConfigProperty('title', v ?? undefined)}
                            saveOnBlur={true}
                            displayComponent={<EasyEditOverflowWrapper value={tileConfig.title ?? ''} />}
                        />
                    </Text.H2>
                    <Text.H4 className='mb-2' data-testid='tileDescription'>
                        <EasyEdit
                            type='text'
                            value={tileConfig.description || undefined}
                            placeholder={!tileConfig.description ? 'Click to add a description' : ''}
                            onSave={(v: string) => setConfigProperty('description', v ?? undefined)}
                            saveOnBlur={true}
                            displayComponent={<EasyEditOverflowWrapper value={tileConfig.title ?? ''} />}
                        />
                    </Text.H4>
                </div>
                <div className='flex items-center justify-end space-x-4'>
                    {variables.length > 0 && (
                        <>
                            {variables.map((variable) => (
                                <DashboardVariableDropdown
                                    key={variable.id}
                                    variable={variable}
                                    disabled={!tileConfig.variables?.length}
                                    theme='dark'
                                />
                            ))}
                            <div className='border-r-2 border-dividerPrimary h-[25px]'></div>
                        </>
                    )}

                    <AnalyticsToggle />
                    <div className='border-r-2 border-dividerPrimary h-[25px]'></div>
                    <Button variant='secondary' onClick={() => (isEdited ? setConfirmDiscard(true) : onClose())} data-testid='tileEditorClose'>
                        {isEdited ? 'Discard' : 'Close'}
                    </Button>
                    <Button variant='primary' disabled={!isEdited || containsError} onClick={() => onSave()} data-testid='tileEditorSave'>
                        Save
                    </Button>
                    <DataStreamTileEditorTileOptions />
                </div>
            </header>
            {confirmDiscard && (
                <ConfirmationPrompt
                    title='Discard Changes'
                    prompt="Any changes you've made will be lost"
                    onConfirm={() => onClose()}
                    onClose={() => setConfirmDiscard(false)}
                    confirmButtonText='Discard'
                    confirmButtonVariant='destructive'
                />
            )}
        </>
    );
};
