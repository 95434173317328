import { useAppContext } from 'contexts/AppContext';
import type { DashboardType } from 'dashboard-engine/types/Dashboard';
import { flatMap } from 'lodash';
import { useDashboardsForWorkspace } from 'queries/hooks/useDashboardsForWorkspace';
import { useDeleteDashboards } from 'queries/hooks/useDeleteDashboards';
import { useUpdateWorkspaceDashboardOrder } from 'queries/hooks/useUpdateWorkspaceDashboardOrder';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import {
    dashboardsSortedByWorkspaceOrder,
    flattenedDashboardsSortedByWorkspaceOrder,
    isDashboard,
    isFolder,
    type DashboardFolder
} from 'queries/utils/dashboardSorted';
import { useCallback } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { Instruction, tree } from './DashboardTreeUtils';
import { useSetFolderOpen } from './useFoldersOpen';

export function useApplyDashboardInstruction() {
    const { currentWorkspaceID } = useAppContext();
    const { data: workspace } = useWorkspace(currentWorkspaceID);

    const { data: dashboards = [] } = useDashboardsForWorkspace(currentWorkspaceID, {
        suspense: true,
        enabled: Boolean(workspace),
        select: dashboardsSortedByWorkspaceOrder(workspace?.data.properties?.dashboardIdOrder)
    });

    const { data: flattendDashboards = [] } = useDashboardsForWorkspace(currentWorkspaceID, {
        select: flattenedDashboardsSortedByWorkspaceOrder(workspace?.data?.properties?.dashboardIdOrder)
    });

    const navigate = useNavigate();

    const updateOrder = useUpdateWorkspaceDashboardOrder();

    const setOpen = useSetFolderOpen();

    const dashboardPage = useMatch('/dashboard/:id');

    const deleteDashboards = useDeleteDashboards();

    return useCallback(
        ({ instruction, itemId, targetId }: { instruction: Instruction; itemId: string; targetId: string }) => {
            if (
                (itemId === targetId && instruction.type !== 'reparent') ||
                instruction.type === 'instruction-blocked'
            ) {
                return;
            }

            const item = tree.find(dashboards, itemId);
            if (!item) {
                return;
            }

            let result: (DashboardFolder | DashboardType)[] = [];

            switch (instruction.type) {
                case 'reorder-above': {
                    // moving an item above another
                    result = tree.remove(dashboards, itemId);
                    result = tree.insertBefore(result, targetId, item);
                    break;
                }
                case 'reorder-below': {
                    // Moving an item below another
                    result = tree.remove(dashboards, itemId);
                    result = tree.insertAfter(result, targetId, item);
                    break;
                }
                case 'make-child': {
                    // Just dragging into a folder
                    result = tree.remove(dashboards, itemId);
                    result = tree.insertChild(result, targetId, item);

                    setOpen([{ itemId: tree.find(dashboards, targetId)!.id, open: true }]);
                    break;
                }
                case 'reparent': {
                    // We are shifting the item up or down in the hierarchy. This happens when at the end of a folder
                    const path = tree.getPathToItem({
                        current: dashboards,
                        targetId: targetId
                    });
                    const desiredId = path![instruction.desiredLevel];
                    result = tree.remove(dashboards, itemId);
                    result = tree.insertAfter(result, desiredId, item);
                    break;
                }
                case 'add-folder': {
                    result = tree.insertChild(dashboards, itemId, instruction.newFolder);
                    setOpen([
                        { itemId: instruction.newFolder.id, open: true },
                        { itemId, open: true }
                    ]);
                    break;
                }
                case 'rename': {
                    item.name = instruction.name;
                    result = dashboards;
                    break;
                }
                case 'remove-folder': {
                    if (!isFolder(item)) {
                        break;
                    }
                    result = dashboards;
                    if (!instruction.deleteChildren) {
                        item.children.forEach((child) => (result = tree.insertBefore(result, itemId, child)));
                    } else {
                        const getDashboards = (items: (DashboardType | DashboardFolder)[]): DashboardType[] =>
                            flatMap(items, (child) => (isDashboard(child) ? [child] : getDashboards(child.children)));
                        const dashboardsToDelete = getDashboards(item.children);
                        deleteDashboards.mutate(dashboardsToDelete);

                        if (dashboardPage && dashboardsToDelete.some((dash) => dash.id === dashboardPage.params.id)) {
                            // If we are current on one of the dashboard pages, we should navigate to another
                            const targetDash = flattendDashboards.filter((dash) =>
                                dashboardsToDelete.every((d) => d.id !== dash.id)
                            )[0];
                            navigate(targetDash ? `/dashboard/${targetDash.id}` : `/workspace/${currentWorkspaceID}`);
                        }
                    }
                    result = tree.remove(result, itemId);
                    break;
                }

                default:
                    // eslint-disable-next-line no-console
                    console.error('Unknown instruction');
            }

            updateOrder.mutate(result);
        },
        [
            dashboards,
            updateOrder,
            setOpen,
            deleteDashboards,
            dashboardPage,
            flattendDashboards,
            navigate,
            currentWorkspaceID
        ]
    );
}
