import { InfoTip } from '@/components/InfoTip';
import Text from '@/components/Text';
import { Toggle } from '@/components/forms/Toggle';
import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { isFeatureEnabled } from '@squaredup/tenants';
import LoadingSpinner from 'components/LoadingSpinner';
import { FeatureUnavailableBanner } from 'components/plans/FeatureUnavailableBanner';
import { useDashboardContext } from 'contexts/DashboardContext';
import type { KPIType } from 'dynamo-wrapper';
import { useTier } from 'queries/hooks/useTier';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { KPI_TYPES, ListTypes } from 'services/KPIService';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { useDataStreamSupportedTimeframes } from '../hooks/useDataStreamSupportedTimeframes';
import { determineBestTimeframe } from '../monitoring/DataStreamTileEditorMonitoring';
import { DataStreamTileEditorKPIForm } from './DataStreamTileEditorKPIForm';

const KPI = () => {
    const { timeframe: dashboardTimeframe, variables = [] } = useDashboardContext();
    const { data: tier, isLoading: isLoadingTier } = useTier();
    const isFeatureAvailable = tier && isFeatureEnabled(tier, 'kpiTypes');
    const { data: kpiTypes, isLoading: isLoadingKpiTypes } = useQuery(KPI_TYPES, ListTypes, {
        enabled: isFeatureAvailable
    });

    const types: KPIType[] | undefined = kpiTypes;

    const { tileConfig, savedTileConfig, setTileConfig } = useTileEditorContext();

    const supportedTimeframes = useDataStreamSupportedTimeframes(tileConfig?.dataStream?.id);

    const [kpiEnabled, setKpiEnabled] = useState(Boolean(tileConfig?.kpi));

    const handleKpiToggle = () => {
        setTileConfig((currentConfig) => {
            const timeframe = determineBestTimeframe(supportedTimeframes, dashboardTimeframe, currentConfig.timeframe);

            return {
                ...currentConfig,
                kpi: !kpiEnabled ? savedTileConfig?.kpi : undefined,
                timeframe: !kpiEnabled ? timeframe : currentConfig.timeframe
            };
        });
        setKpiEnabled(!kpiEnabled);
    };

    const isLoading = isLoadingTier || isLoadingKpiTypes;
    const tileVariables = variables.filter((v) => (tileConfig.variables || []).includes(v.id));
    const variablesPreventingKpis = tileVariables.length > 0 && tileVariables.every((v) => v.default !== 'all');
    const isKpiAvailable = isFeatureAvailable && !variablesPreventingKpis;

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div
            className='px-4 pt-5 overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary h-max'
            data-panel='kpi'
        >
            <FeatureUnavailableBanner
                featureKey='kpiTypes'
                container='modal'
                content='narrow'
                className='mb-5'
                title='KPIs'
            />

            <div className='flex items-center justify-between shrink-0'>
                <Text.H3 className='flex-shrink-0'>KPI</Text.H3>

                <div>
                    <Toggle
                        checked={kpiEnabled && isKpiAvailable}
                        onCheckedChange={handleKpiToggle}
                        disabled={!isKpiAvailable}
                    />
                </div>
            </div>

            {variablesPreventingKpis && (
                <div>
                    <InfoTip icon={faWarning} iconClassName='text-statusWarningPrimary'>
                        KPI is not available as this tile uses a variable without a default value.{' '}
                        <a
                            className='text-textLink'
                            href='https://docs.squaredup.com/first-steps/dashboards/dashboard-variables'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Learn more.
                        </a>
                    </InfoTip>
                </div>
            )}

            {kpiEnabled && isKpiAvailable && (
                <div className='mt-[29px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                    <DataStreamTileEditorKPIForm types={types} />
                </div>
            )}
        </div>
    );
};

export default KPI;
