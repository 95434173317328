import { sortBy } from 'lodash';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { DATA_STREAM_DEFINITIONS_FOR_WORKSPACE } from 'services/DataStreamDefinitionService';
import { CreateDefaultDashboards } from 'services/PluginService';
import { useSetFolderOpen } from 'ui/nav/components/dashboards/useFoldersOpen';

export const useDefaultDashboardCreation = (navigateOnCreate: boolean = false) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const setFoldersOpen = useSetFolderOpen();

    return useMutation(
        ({ configId, pluginId, workspaceId }: { configId: string; pluginId: string; workspaceId: string }) =>
            CreateDefaultDashboards(configId, pluginId, workspaceId),
        {
            onSuccess: (dashboards) => {
                queryClient.invalidateQueries(dashboardQueryKeys.all);
                queryClient.invalidateQueries(workspaceQueryKeys.all);
                queryClient.removeQueries(scopeQueryKeys.all);
                queryClient.removeQueries([DATA_STREAM_DEFINITIONS_FOR_WORKSPACE]);

                const folder = dashboards[0]?.rootFolder;
                if (folder) {
                    setFoldersOpen([{ itemId: folder.id, open: true }]);
                }

                if (navigateOnCreate && dashboards.length) {
                    const sortedDashboards = sortBy(
                        dashboards,
                        (dashboard) => dashboard.displayName?.toLowerCase() ?? ''
                    );
                    navigate(`/dashboard/${sortedDashboards[0]?.id}`);
                }
            }
        }
    );
};
