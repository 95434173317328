import { AppContext } from 'contexts/AppContext';
import { DashboardType } from 'dashboard-engine/types/Dashboard';
import trackEvent from 'lib/analytics';
import { useDashboardsForWorkspace } from 'queries/hooks/useDashboardsForWorkspace';
import { useUpdateWorkspaceDashboardOrder } from 'queries/hooks/useUpdateWorkspaceDashboardOrder';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { streamDataKeys } from 'queries/queryKeys/streamDataKeys';
import { dashboardsSortedByWorkspaceOrder, isFolder } from 'queries/utils/dashboardSorted';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Create as CreateDashboard } from 'services/DashboardService';
import { tree } from 'ui/nav/components/dashboards/DashboardTreeUtils';
import { useSetFolderOpen } from 'ui/nav/components/dashboards/useFoldersOpen';

export const useCreateDashboardAndNavigate = () => {
    const { currentWorkspaceID } = useContext(AppContext);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { data: workspace } = useWorkspace(currentWorkspaceID!);

    const { data: orderedDashboards = [] } = useDashboardsForWorkspace(currentWorkspaceID, {
        suspense: true,
        enabled: Boolean(workspace),
        select: dashboardsSortedByWorkspaceOrder(workspace?.data.properties?.dashboardIdOrder)
    });

    const updateDashOrder = useUpdateWorkspaceDashboardOrder();

    const setFolderOpen = useSetFolderOpen();

    const createDashboardAndNavigate = async (opts?: { folderId?: string }) => {
        const newDashboard = await CreateDashboard(currentWorkspaceID || '');

        // Populate query data where possible, to avoid triggering load from server and/or speed up
        // display of new data, e.g. show new dashboard in nav before the full dashboard list is reloaded.
        queryClient.setQueryData(dashboardQueryKeys.detail(newDashboard.id), newDashboard);
        queryClient.setQueryData(dashboardQueryKeys.variables(newDashboard.id), []);
        const dashboards = queryClient.getQueryData<DashboardType[]>(dashboardQueryKeys.list);
        if (dashboards && !dashboards.find((dashboard) => dashboard.id === newDashboard.id)) {
            queryClient.setQueryData(dashboardQueryKeys.list, [...dashboards, newDashboard]);
        }

        if (opts?.folderId) {
            const folder = tree.find(orderedDashboards, opts.folderId);
            if (folder && isFolder(folder)) {
                folder.children.push(newDashboard);
                setFolderOpen([{ itemId: folder.id, open: true }]);
            }
        } else {
            orderedDashboards.push(newDashboard);
        }

        updateDashOrder.mutate(orderedDashboards);

        trackEvent('Dashboard Created', { id: newDashboard.id, workspace: currentWorkspaceID });
        navigate(`/dashboard/${newDashboard.id}?editing`);

        // Global overview page
        queryClient.invalidateQueries(streamDataKeys.forDataStream('datastream-health'));
    };

    return {
        createDashboardAndNavigate
    };
};
