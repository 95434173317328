import { useTenant } from 'queries/hooks/useTenant';
import { graphQueryKeys } from 'queries/queryKeys/graphQueryKeys';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetchConnectedWorkspaceData } from './fetchConnectedWorkspaceData';
import { NodesAndEdges } from './types';

type QueryReturnType = NodesAndEdges | undefined;
type QuerySelectReturnType = QueryReturnType;

export const useGlobalMapData = (
    queryOptions?: UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>
) => {
    const { data: tenant } = useTenant();

    return useQuery(
        graphQueryKeys.global,
        async () => fetchConnectedWorkspaceData(tenant!),
        {
            enabled: Boolean(tenant),
            ...queryOptions
        }
    );
};
