import { Node } from '@squaredup/graph';
import { Serialised } from '@squaredup/ids';
import { getNameForSource } from 'dashboard-engine/util/getNameForSource';
import { getNodeTitle } from 'dashboard-engine/util/graphDataToNetworkData';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import { getDrilldownLink } from 'lib/drilldownLinks';
import { DashboardTypeWithFolderPath } from 'queries/utils/dashboardSorted';
import { Search as SearchService } from 'services/SearchService';
import { PluginSourceConfig } from 'services/SourceConfigService';
import { Workspace } from 'services/WorkspaceService';
import { GlobalSearchResult } from './GlobalSearch';
import { parseSearchString } from './parseSearchString';

interface DataStreams {
    dataStream?: Serialised<ProjectedDataStreamDefinitionEntity>;
    availableDataStreams: Serialised<ProjectedDataStreamDefinitionEntity>[];
}

function formatDataSources(datasources: PluginSourceConfig[], node: Node) {
    return node.sourceName?.map((s) => getNameForSource(datasources, node, s) as string) ?? [];
}

function getFolderPaths(dashboards: DashboardTypeWithFolderPath[], node: Node): string[] | undefined {
    if ('dashId' in node) {
        return dashboards.find(({ id }) => id === node.dashId?.[0])?.folderPath;
    }
    if (node.type?.[0] === 'dash') {
        return dashboards.find(({ id }) => id === node.sourceId?.[0])?.folderPath;
    }

    return undefined;
}

function formatSearchResults(
    datasources: PluginSourceConfig[],
    dashboards: DashboardTypeWithFolderPath[],
    resultGroups: Record<string, Node[]>,
    dataStreamMatches: Map<string, DataStreams>
) {
    const formatted: Record<string, GlobalSearchResult[]> = {};

    Object.entries(resultGroups).forEach(([groupName, nodes]) => {
        formatted[groupName] = nodes.map(
            (n): GlobalSearchResult => ({
                node: n,
                title: getNodeTitle(n),
                sources: n.sourceName ? formatDataSources(datasources, n) : [],
                link: getDrilldownLink(n),
                dataStream: dataStreamMatches.get(n.id)?.dataStream,
                availableDataStreams: dataStreamMatches.get(n.id)?.availableDataStreams,
                folderPath: getFolderPaths(dashboards, n)
            })
        );
    });

    return formatted;
}

// For performance, only fetch the graph properties we need to display the search results
const globalSearchResultProperties = [
    'name',
    'sourceType',
    'sourceName',
    'type',
    '__configId',
    'sourceId',
    'dashId',
    'dashName',
    'tileName',
    'state'
];

/**
 * Create a function that performs a global search
 * @param dataStreamsIndex
 * @param datasources
 * @params workspaces Workspaces the user has direct access to (i.e. has permission to navigate to)
 * @returns
 */
export const globalSearchHandler = (
    datasources: PluginSourceConfig[] = [],
    workspaces: Workspace[] | undefined,
    dashboards: DashboardTypeWithFolderPath[] = []
) => {
    return async (searchQuery: string) => {
        const { nodeSearch } = parseSearchString(searchQuery);
        const results = await SearchService(nodeSearch, globalSearchResultProperties, workspaces);
        return formatSearchResults(datasources, dashboards, results, new Map());
    };
};
